<template>
  <div>
    <TopNavbar />
    <div v-if="!$store.isLoading" class="bg-light">
      <router-view />
    </div>
    <div v-else class="loading">Loading...</div>
  </div>
</template>

<script>
  import TopNavbar from './components/TopNavbar.vue';

  export default {
    components: {
      TopNavbar
    },
    data() {
      return {
      };
    },
    async mounted() {
      await this.$store.fetchState();
    }
  };
</script>