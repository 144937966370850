import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', {
    setup($http) {
        this.$http = $http;
    },
    getters: {
    },
    state: () => {
        return {
            user: null,
            publicChallenges: [],
            isAuthenticated: false,
            $http: null,
            isLoading: true,
            selectedDraft: null
        }
    },
    actions: {
        setHttp(http) {
            this.$http = http;
        },
        async setCsrfToken() {
            await this.$http.get('users/set-csrf-token')
        },
        async login(email, password, remember_me) {
            try {
                const response = await this.$http.post('users/login', { data: { email, password, remember_me } })
                
                if (response.status === 200) {
                    if (response.data.requires_otp) {
                        return { requires_otp: true }
                    } else {
                        this.user = response.data.user
                        this.isAuthenticated = true
                        return { requires_otp: false }
                    }
                } else {
                    this.user = null
                    this.isAuthenticated = false
                }
            } catch (error) {
                this.user = null
                this.isAuthenticated = false
                throw error
            }
        },
        async sendEmailOTP() {
            try {
                const response = await this.$http.post('users/send-email-otp')

                if (response.status === 200) {
                    return { success: true }
                }
            } catch (error) {
                console.error("Error sending email OTP:", error)
                return { success: false }
            }
        },
        async verifyEmailOTP(token) {
            try {
                const response = await this.$http.post('users/verify-email-otp', { token: token })
                if (response.status === 200) {
                    return { success: true, message: response.data.message }
                }
            } catch (error) {
                console.error('Error verifying email OTP', error)
                return { success: false }
            }
        },
        async generateTOTP() {
            try {
                const response = await this.$http.post('users/generate-totp')
                if (response.status === 200) {
                    return { 
                        success: true,
                        qrCode: response.data.qr_code,
                        secret: response.data.secret,
                        uri: response.data.uri,
                        devices: response.data.devices
                    }
                }
            } catch (error) {
                console.error("Error generating TOTP:", error)
                return { success: false }
            }
        },
        async verifyTOTP(token, deviceName) {
            try {
                const response = await this.$http.post('users/verify-totp', { token: token, name: deviceName })
                if (response.status === 200) {
                    return { success: true, message: response.data.message }
                }
            } catch (error) {
                console.error('Error verifying TOTP', error)
                return { success: false }
            }
        },
        async verifyOTP(email, otp, remember_me) {
            try {
                const response = await this.$http.post('users/verify-otp', { otp_code: otp, email, remember_me });
        
                if (response.status === 200) {
                    this.isAuthenticated = true;
                    this.user = response.data.user;
                    return { success: true };
                } else {
                    return { success: false, message: 'Invalid OTP. Please try again.' };
                }
            } catch (error) {
                return error.response.data
            }
        },
        async logout(router=null) {
            try {
                const response = await this.$http.post('users/logout')
                if (response.status == 200) {
                    if (router) {
                        await router.push({name: "login"})
                    }
                    this.user = null
                    this.isAuthenticated = false
                }
            } catch (error) {
                console.error('Logout failed', error)
                throw error
            }
        },
        async fetchState() {
            this.isLoading = true
            await this.fetchCurrentUser()
            await this.fetchPublicChallenges()
            this.isLoading = false;
        },
        async fetchCurrentUser() {
            if (!this.isAuthenticated) {
                return
            }
            try {
                const response = await this.$http.get('users/current');
                if (response.status === 200) {
                    const currentUser = response.data
                    if (currentUser) {
                        this.user = currentUser;
                        this.isAuthenticated = true;
                    }
                }
            } catch (error) {
                console.error(error)
            }
        },
        async updateUser(updatedUser) {
            try {
                const response = await this.$http.put(`users/${this.user.id}`, updatedUser);
                if (response.status === 200) {
                    this.user = response.data
                }
            } catch(error){
                console.error(error);
            }
        },
        async fetchPublicChallenges() {
            try {
                const response = await this.$http.get('challenges/')
                if (response.status === 200) {
                    this.publicChallenges = response.data
                }
            } catch (error) {
                console.log(error)
            }
        },
        async createNewDraft() {
            try {
                const response = await this.$http.post('challenges/', { organizer: this.user.id });
                if (response.status === 201) {
                    return { success: true, data: response.data }
                }
            } catch (error) {
                console.error('Error creating new draft:', error);
            }
        },
        async updateDraft(challengeId, formData, fileUpload=false) {
            try {
                const config = fileUpload ? {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                } : {}
                const response = await this.$http.put(`challenges/${challengeId}`, formData, config);
                if (response.status === 200) {
                    return { success: true, data: response.data };
                }
            } catch (error) {
                console.error('Error updating challenge:', error);
                if (error?.response?.data?.errors) {
                    console.log(error.response.data.errors)
                    return { success: false, errors: error.response.data.errors };
                }
            }
        },
        async deleteChallenge(challengeId) {
            try {
                const response = await this.$http.delete(`challenges/${challengeId}`)
                if (response.status === 204) {
                    return { success: true };
                }
            } catch (error) {
                console.error('Error deleting challenge:', error)
            }
        },
        async createCategory(challengeId) {
            try {
                const response = await this.$http.post(`challenges/${challengeId}/categories`);
                if (response.status === 201) {
                    const newCategory = response.data
                    this.user.created_drafts[challengeId].categories[newCategory.id] = newCategory
                }
            } catch (error) {
                console.error('Error creating new category:', error);
            }
        },
        async updateCategory(challengeId, updatedCategory) {
            try {
                const response = await this.$http.put(`challenges/${challengeId}/categories/${updatedCategory.id}`, updatedCategory);
                if (response.status === 200) {
                    this.user.created_drafts[challengeId].categories[updatedCategory.id];
                    return true
                }
            } catch (error) {
                console.error('Error updating category:', error);
            }
        },
        async deleteCategory(challengeId, categoryId) {
            try {
                const response = await this.$http.delete(`challenges/${challengeId}/categories/${categoryId}`);
                if (response.status === 204) {
                    delete this.user.created_drafts[challengeId].categories[categoryId];
                }
            } catch (error) {
                console.error('Error deleting category:', error);
            }
        },
        async createChallengeKey(challengeId, modelId) {
            try {
                const data = {
                    "key": "",
                    "content_type": "challenge",
                    "object_id": challengeId, 
                    "evaluation_model": modelId 
                }
                const response = await this.$http.post(`llm/keys/`, data);
                if (response.status === 201) {
                    this.user.created_drafts[challengeId].ai_models[modelId].api_key = response.data
                }
            } catch (error) {
                console.error('Error creating new key:', error);
            }
        },
        async updateChallengeKey(challengeId, updatedKey) {
            try {
                const response = await this.$http.put(`llm/keys/${updatedKey.id}/`, updatedKey);
                if (response.status === 200) {
                    this.user.created_drafts[challengeId].ai_models[updatedKey.evaluation_model].api_key = response.data
                    return true
                }
            } catch (error) {
                console.error('Error updating category:', error);
            }
        },
        async deleteChallengeKey(challengeId, modelId, keyId) {
            try {
                const response = await this.$http.delete(`llm/keys/${keyId}/`);
                if (response.status === 204) {
                    this.user.created_drafts[challengeId].ai_models[modelId].api_key = null
                }
            } catch (error) {
                console.error('Error deleting key:', error);
            }
        },
        async getUpdatedPrompts(challengeId) {
            try {
                const response = await this.$http.get(`/challenges/${challengeId}/prompts`)
                if (response.status === 200) {
                    this.fetchCurrentUser()
                }
            } catch (error) {
                console.error('Error retrieving updated prompts:', error)
            }
        }
    }
})

export function getCSRFToken() {
    /*
    We get the CSRF token from the cookie to include in our requests.
    This is necessary for CSRF protection in Django.
     */
    const name = 'csrftoken';
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    if (cookieValue === null) {
        throw 'Missing CSRF cookie.'
    }
    return cookieValue;
}

