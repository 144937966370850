import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { configureDynamicRoutes } from './router'
import { formatDate, formatDateSimple } from './filters'
import App from './App.vue'
import { useAuthStore } from './store/auth'
import axios from 'axios'
import './assets/styles/custom-bootstrap.min.css'
import './assets/styles/global.css'
import 'bootstrap'
import "bootstrap-icons/font/bootstrap-icons.css"
import { BootstrapVue3 } from 'bootstrap-vue-3'
import BootstrapIconsPlugin from 'bootstrap-vue-3'

const app = createApp(App);

app.use(createPinia())

const hostURL = process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000'
    : 'https://topprompt.byu.edu'
    
const apiURL = hostURL + '/api/'

const customAxios = axios.create({
    baseURL: apiURL,
    withCredentials: true,
});

const csrfToken = document.cookie
    .split('; ')
    .find(row => row.startsWith('csrftoken='))
    ?.split('=')[1];

// Add an interceptor to set the CSRF token
customAxios.interceptors.request.use(function (config) {
    
    if (csrfToken) {
        config.headers['X-CSRFToken'] = csrfToken;
    }
    return config;
    }, function (error) {
    return Promise.reject(error);
});
  
axios.defaults.headers.common['X-CSRFToken'] = csrfToken

app.config.globalProperties.$http = customAxios;
app.config.globalProperties.$formatDate = formatDate
app.config.globalProperties.$formatDateSimple = formatDateSimple
app.config.globalProperties.$hostURL = hostURL;

const authStore = useAuthStore()
authStore.setHttp(customAxios)
authStore.setCsrfToken()
app.config.globalProperties.$store = authStore;

app.use(BootstrapVue3)
app.use(BootstrapIconsPlugin)
configureDynamicRoutes(app.config.globalProperties.$http, app.config.globalProperties.$store).then((router) => {
    app.use(router);
    app.mount('#app');
})
