<template>
  <div id="main-content">
    <div class="container pt-5">
      <template v-if="$store.user && $store.isAuthenticated">
        <h2 class="headers">Registered challenges</h2><br>
        <div class="search-box">
          <input type="text" id="searchRegisteredChallenges" class="form-control" placeholder="Search registered challenges...">
        </div>

        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Challenge</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody id="registeredChallenges">
            <tr :key="challenge.id" v-for="challenge in this.$store.user.joined_challenges">
              <td>
                <router-link :to="{ name: 'challenge-detail', params: { id: challenge.id } }">{{ challenge.title }}</router-link>
              </td>
              <td>{{ formatDate(challenge.start) }}</td>
              <td>{{ formatDate(challenge.end) }}</td>
            </tr>
          </tbody>
        </table>
      </template>

      <h2 class="headers">Open for registration</h2><br>
      <div class="search-box">
        <input type="text" id="searchAvailableChallenges" class="form-control" placeholder="Search available challenges...">
      </div>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Challenge</th>
            <th>Start Date</th>
            <th>End Date</th>
          </tr>
        </thead>
        <tbody id="availableChallenges">
          <tr v-for="challenge in availableChallengesForUser" :key="challenge.id">
            <td>
              <router-link :to="{ name: 'challenge-register', params: { id: challenge.id } }">{{ challenge.title }}</router-link>
            </td>
            <td>{{ formatDate(challenge.start) }}</td>
            <td>{{ formatDate(challenge.end) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
    };
  },
  computed: {
    availableChallengesForUser() {
      if (!this.$store.user) {
        return this.$store.publicChallenges
      } else {
        const joinedChallengeKeys = new Set(Object.keys(this.$store.user.joined_challenges));
        const availableChallenges = { ...this.$store.user.available_challenges, ...this.$store.user.created_challenges }
        const availableChallengesToJoin = Object.fromEntries(
          Object.entries(availableChallenges).filter(([key]) => !joinedChallengeKeys.has(key))
        )
        return availableChallengesToJoin
      }
    },
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    }
  }
};
</script>

<style scoped>
  .headers {
    font-weight: bold;
  }
</style>
