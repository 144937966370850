export const formatDate = (date) => {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
    };
    return new Date(date).toLocaleDateString(undefined, options);
};

export const formatDateSimple = (date) => {
    const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    };
    return new Date(date).toLocaleDateString(undefined, options);
};