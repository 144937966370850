import { createRouter, createWebHistory } from 'vue-router';
import DraftDetail from './pages/organizer/draft/DraftDetail.vue';
import ChallengesPage from './pages/challenge/ChallengesPage.vue';
import ChallengeDetail from './pages/challenge/ChallengeDetail.vue';
import ChallengeRegister from './pages/challenge/ChallengeRegister.vue';
import LoginUser from './pages/user/LoginUser.vue';
import RegisterUser from './pages/user/RegisterUser.vue';
import UpdateAccount from './pages/user/UpdateAccount.vue';
import HomePage from './pages/HomePage.vue';

export function fetchSetupParts($http) {
    return $http.get('challenges/setup-parts')
        .then(response => response.data)
        .catch(error => {
            console.error('Error fetching setup parts:', error);
            throw error;
        });
}

export async function configureDynamicRoutes($http, $store) {
    try {
        const setupParts = await fetchSetupParts($http);
        const dynamicRoutes = setupParts.map(part => ({
            name: part.route,
            path: part.route,
            component: () => import(`./pages/organizer/draft/${part.component}.vue`),
            props: true,
            meta: { icon: part.icon, title: part.title, order: part.order, id: part.id, requiresAuth: true } // Requires authentication
        }));

        const routes = [
            {
                path: '/',
                name: 'home',
                component: HomePage,
                meta: { requiresAuth: false }
            },
            {
                path: '/challenges',
                name: 'challenges',
                component: ChallengesPage,
                meta: { requiresAuth: false }
            },
            {
                path: '/login',
                name: 'login',
                component: LoginUser,
                meta: { requiresAuth: false }
            },
            {
                path: '/account',
                name: 'account',
                component: UpdateAccount,
                meta: { requiresAuth: true }
            },
            {
                name: 'two-factor',
                path: '/two-factor',
                component: () => import('./pages/user/TwoFactor.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/register',
                name: 'register',
                component: RegisterUser,
                meta: { requiresAuth: false }
            },
            {
                path: '/privacy',
                name: 'privacy-policy',
                component: () => import('./pages/policies/PrivacyPolicy.vue'),
                meta: { requiresAuth: false }
            },
            {
                path: '/cookies',
                name: 'cookies-policy',
                component: () => import('./pages/policies/CookiesPolicy.vue'),
                meta: { requiresAuth: false }
            },
            {
                path: '/challenge/:id',
                name: 'challenge-detail',
                component: ChallengeDetail,
                meta: { requiresAuth: true },
                children: [
                    {
                        name: 'challenge-register',
                        path: 'register',
                        component: ChallengeRegister,
                        props: true,
                        meta: { requiresAuth: true }
                    },
                    {
                        name: 'prompt-editor',
                        path: 'prompt',
                        component: () => import('./pages/challenge/PromptEditor.vue'),
                        meta: { requiresAuth: true }
                    },
                ]
            },
            {
                path: '/organizer',
                name: 'organizer',
                component: () => import('./pages/organizer/OrganizerLayout.vue'),
                meta: { requiresAuth: true },
                children: [
                    {
                        path: '',
                        redirect: { name: 'organizer-dashboard' }
                    },
                    {
                        name: 'organizer-dashboard',
                        path: 'dashboard',
                        component: () => import('./pages/organizer/OrganizerDashboard.vue'),
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'challenge/:id',
                        name: 'organizer-challenge-detail',
                        component: ChallengeDetail,
                        meta: { requiresAuth: true }
                    },
                    {
                        name: 'draft-detail',
                        path: 'draft/:id',
                        component: DraftDetail,
                        props: true,
                        meta: { requiresAuth: true },
                        children: dynamicRoutes
                    }
                ]
            },
        ];

        const router = createRouter({
            history: createWebHistory(),
            routes
        });

        // Global navigation guard to check authentication
        router.beforeEach((to, from, next) => {
            const isAuthenticated = $store.isAuthenticated;

            if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
                next({ name: 'login' });
            } else {
                next();
            }
        });

        return router;
    } catch (error) {
        console.error('Error configuring dynamic routes:', error);
        return null;
    }
}
