<template>
  <div class="">
      <div class="d-flex flex-column vh-100 mt-0 align-items-center justify-content-center">
        <div class="container">
          <div class="row p-5 bg-white rounded h-100">
            <div class="col-12 col-md-4">
              <h1 class="fw-bolder">Create an Account</h1>
            </div>
            <div class="col-12 col-md-8 d-flex flex-column align-items-center justify-content-center">
              <input type="text" v-model="firstName" placeholder="First Name" autocapitalize="on" autocomplete="given-name" class="form-control-lg border-0 w-100 mt-5 mb-2 bg-light" required>
              <template v-if="error && 'firstName' in error"><p v-for="(err, index) in error['firstName']" :key="index" class="text-danger">{{ err['message'] }}</p></template>
              <input type="text" v-model="lastName" placeholder="Last Name" autocapitalize="on" autocomplete="family-name" class="form-control-lg border-0 w-100 mt-5 mb-2 bg-light" required>
              <template v-if="error && 'lastName' in error"><p v-for="(err, index) in error['lastName']" :key="index" class="text-danger">{{ err['message'] }}</p></template>
              <input v-model="email" class="form-control-lg border-0 w-100 mt-5 mb-2 bg-light" placeholder="Email address" type="text" autofocus="" autocapitalize="none" autocomplete="email" maxlength="150" required>
              <template v-if="error && 'email' in error"><p v-for="(err, index) in error['email']" :key="index" class="text-danger">{{ err['message'] }}</p></template>
              <input v-model="password" type="password" class="form-control-lg border-0 w-100 mt-5 bg-light" placeholder="Password" autocomplete="current-password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required>
              <template v-if="error && 'password' in error"><p v-for="(err, index) in error['password']" :key="index" class="text-danger">{{ err['message'] }}</p></template>
              <input v-model="passwordConfirm" type="password" class="form-control-lg border-0 w-100 mt-5 bg-light" placeholder="Confirm Password" autocomplete="current-password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required>
              <template v-if="error && 'passwordConfirm' in error"><p v-for="(err, index) in error['passwordConfirm']" :key="index" class="text-danger">{{ err['message'] }}</p></template>
              <div class="row text-end">
                <span>Already have an account? <a href="/login">Sign In</a></span>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid d-flex justify-content-end position-absolute bottom-0">
          <div class="col-12 col-lg-4 text-end">
            <button type="submit" class="btn btn-primary btn-lg col-3 m-5" @click="register">Register</button>
          </div>
        </div>
      </div>
  </div>
</template>
  
<script>  
  export default {
    data() {
      return {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirm: '',
        error: {},
      }
    },
    methods: {
      async register() {
        try {
          if (this.password !== this.passwordConfirm) {
            this.error['passwordConfirm'] = [{"message":"Passwords do not match"}]
            return
          }
          if (this.password.length < 12) {
            this.error['passwordConfirm'] = [{"message":"Passwords must be at least 12 characters long."}]
            return
          }
          for (const field of ['firstName', 'lastName', 'email', 'password']) {
            if (!this[field]) {
              this.error[field] = [{"message":"This field is required."}]
              return
            } else {
              delete this.error[field]
            }
          }
          const data = {
            first_name: this.firstName,
            last_name: this.lastName,
            email: this.email,
            username: this.email,
            password: this.password
          }
          const response = await this.$http.post('users/register', { data: data });
          if (response.status === 201) {
            setTimeout(() => {
              this.$router.push('/login')
            }, 1000)
          } else {
            this.error = response.data.error
          }
        } catch (err) {
          console.log(err)
          this.error = JSON.parse(err.response.data.error)
        }
      }
    }
  }
  </script>
  