<template>
    <div id="main-content">
        <div id="background" :style="backgroundStyle" class="p-5 text-center rounded-3 position-relative">
            <div class="mask position-absolute top-0 start-0 w-100 h-100 bg-dark opacity-50"></div>
            <div class="d-flex justify-content-center align-items-center h-100 position-relative z-2">
                <div class="text-white mb-3" style="
                    padding-left: 20vw;
                    padding-right: 20vw;
                ">
                    <h1 style="font-size: 60px;">Compete to shape the future of AI with your innovative prompts! 🚀</h1>
                    <button @click="$router.push({ name: 'challenges' })" class="btn btn-primary btn-xl fs-3 fw-bold mt-3" role="button">Start now!</button>
                </div>
            </div>
        </div>
        <div class="container-fluid mt-5">
            <div class="d-flex justify-content-around">
                <div class="text-center">
                    <h1><i class="bi bi-stars text-info" style="font-size: 70px;"></i></h1>
                    <h3>Up your AI Game</h3>
                    <p>Increase your prompt engineering skills and learn from others.</p>
                </div>
                <div class="text-center">
                    <h1><i class="bi bi-trophy text-info" style="font-size: 70px;"></i></h1>
                    <h3>Compete to Win</h3>
                    <p>Get prizes by creating the best prompts on some challenges.</p>
                </div>
                <div class="text-center">
                    <h1><i class="bi bi-people text-info" style="font-size: 70px;"></i></h1>
                    <h3>Help Researchers</h3>
                    <p>Test your prompts on real datasets to solve real world problems.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            hostURL: this.$hostURL,
        };
    },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `url('${this.hostURL}/media/home-background.png')`,
      };
    },
  },
}
</script>

<style scoped>
    #background {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100vw;
        height: 68vh;
    }
    .btn-xl {
        padding: 1rem 4rem;
        line-height: 1.5;
    }
</style>
