<template>
    <template v-if="challenge">
        <router-view v-if="['challenge-register', 'prompt-editor'].includes($route.name)" :challenge="challenge"></router-view>
        <div v-else :id="$route.name.includes('organizer') ? '' : 'main-content'">
            <div class="row p-5">
                <div class="p-4 bg-white border-0 rounded-4">
                    <div class="d-flex justify-content-between">
                        <div>
                            <h2>{{ challenge.title }} <span v-if="!isChallengeOpen">[Closed]</span></h2>
                            <p v-if="isChallengeOpen" class="text-secondary m-0">Closes on {{ $formatDate(challenge.end) }} ({{ daysUntil(challenge.end) }} days)</p>
                            <p v-else class="text-secondary m-0">Closed on {{ $formatDate(challenge.end) }}</p>
                        </div>
                        <router-link 
                            v-if="!$route.name.includes('organizer')" 
                            :to="{ name: 'prompt-editor' }"
                            :class="{ disabled: !isChallengeOpen }"
                        >
                                <button :disabled="!isChallengeOpen" class="btn btn-primary btn-lg mb-3">Prompt Editor</button>
                        </router-link>
                    </div>
                    <div class="p-4 m-0">
                        <h4>Challenge Summary</h4>
                        <p>{{ challenge.description }}</p>
                        <h4>Instructions</h4>
                        <p v-html="sanitizeInput(challenge.instructions)"></p>
                    </div>
                </div>
            </div>
            <div class="row p-5">
                <div class="p-4 bg-white border-0 rounded-4">
                    <div class="d-flex justify-content-between">
                        <div>
                            <h2>Prompt Rankings</h2>
                            <p class="text-secondary m-0">
                                {{ filteredPrompts.length }} {{ filteredPrompts.length > 1 ? 'prompts' : 'prompt' }} 
                                across {{ challenge.participants_count }} {{ challenge.participants_count > 1 ? 'participants' : 'participant' }}
                            </p>
                        </div>
                        <button @click="checkForUpdates" class="btn btn-primary btn-lg mb-3">Check for updates <i class="bi bi-arrow-clockwise"></i></button>
                    </div>
                    <div class="p-4 m-0">
                        <div class="row">
                            <div class="col-xl-2 col-lg-8 col-sm-12">
                                <select v-model="sortBy" class="form-select">
                                    <option value="" disabled>Select sorting criteria</option>
                                    <option value="created_at" selected>Sort by Date Created</option>
                                    <option value="model">Sort by Model</option>
                                    <option value="accuracy">Sort by Accuracy</option>
                                    <option value="precision">Sort by Precision</option>
                                    <option value="recall">Sort by Recall</option>
                                    <option value="f_score">Sort by Coverage (F-score)</option>
                                </select>
                            </div>
                        </div>
                        <b-table
                            :key="tableKey"
                            :items="filteredPrompts"
                            :fields="fields"
                            :per-page="perPage"
                            :current-page="currentPage"
                            @update:current-page="page => currentPage = page"
                            v-model:sort-by="sortBy"
                            v-model:sort-desc="sortDesc"
                            responsive
                            borderless
                            hover
                            sortable
                        >
                            <template #head(created_at)="data">
                                {{ data.label }}
                                <!-- <button type="button" class="btn btn-link text-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="LLM Model the prompt was submitted to.">
                                    <i class="bi bi-info-circle"></i>
                                </button> -->
                            </template>
                            <template #head(ai_model)="data">
                                {{ data.label }}
                                <button type="button" class="btn btn-link text-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="LLM Model the prompt was submitted to.">
                                    <i class="bi bi-info-circle"></i>
                                </button>
                            </template>
                            <template #head(accuracy)="data">
                                {{ data.label }}
                                <button type="button" class="btn btn-link text-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="How often the model gets the prediction right, out of all tries.">
                                    <i class="bi bi-info-circle"></i>
                                </button>
                            </template>
                            <template #head(precision)="data">
                                {{ data.label }}
                                <button type="button" class="btn btn-link text-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="When the model says something is positive, how often it's actually right.">
                                    <i class="bi bi-info-circle"></i>
                                </button>
                            </template>
                            <template #head(recall)="data">
                                {{ data.label }}
                                <button type="button" class="btn btn-link text-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Out of all the actual positives, how many the model correctly finds.">
                                    <i class="bi bi-info-circle"></i>
                                </button>
                            </template>
                            <template #head(f_score)="data">
                                {{ data.label }}
                                <button type="button" class="btn btn-link text-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="A balance between precision and recall, useful when both are important.">
                                    <i class="bi bi-info-circle"></i>
                                </button>
                            </template>
                            <template #cell(index)="data">
                                {{ data.index + 1 }}
                            </template>
                            <template #cell(name)="data">
                                <span :class="{'fw-bold': data.item.basic_author.id === userId}">
                                    {{ data.item.basic_author.first_name }} {{ data.item.basic_author.last_name }}
                                </span>
                            </template>
                            <template #cell(actions)="data">
                                <button @click="openModal(data.item)" type="button" class="btn btn-link">View Submission</button>
                            </template>
                            <template #cell(accuracy)="data">
                                {{ data.item.accuracy }}
                            </template>
                            <template #cell(precision)="data">
                                {{ data.item.precision }}
                            </template>
                            <template #cell(recall)="data">
                                {{ data.item.recall }}
                            </template>
                            <template #cell(f_score)="data">
                                {{ data.item.f_score }}
                            </template>
                        </b-table>
                        <div class="d-flex justify-content-between align-items-center mt-3">
                            <div class="form-check form-switch">
                                <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="personalBestSwitchCheck"
                                    v-model="personalBestOnly"
                                />
                                <label class="form-check-label" for="personalBestSwitchCheck">
                                    Personal best only
                                </label>
                            </div>
                            <div class="d-flex justify-content-between align-items-center mt-3">
                                <p class="me-3 mb-0">Rows per page</p>
                                <b-form-select v-model="perPage" :options="perPageOptions.map(option => ({ value: option, text: option }))" class="me-5" style="width: 80px !important;">
                                    <option disabled value="">Select items per page</option>
                                </b-form-select>
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="challenge.prompts.length"
                                    :per-page="perPage"
                                    align="center"
                                    class="mb-0"
                                ></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal HTML -->
            <div v-if="showModal" class="modal fade show" tabindex="-1" aria-hidden="false" style="display: block;">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalLabel">{{ modalPrompt.basic_author.first_name }} {{ modalPrompt.basic_author.last_name }}'s prompt from {{ $formatDate(modalPrompt.created_at) }}</h5>
                        </div>
                        <div class="modal-body">
                            {{ modalPrompt.text }}
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Backdrop -->
            <div v-if="showModal" class="modal-backdrop fade show" @click="closeModal"></div>
            </div>
    </template>
    <div v-else>Loading...</div>
</template>

<script>
import DOMPurify from 'dompurify';

export default {
    data() {
        return {
            tableKey: 0,
            currentPage: 1,
            perPage: 10,
            personalBestOnly: false,
            sortBy: 'created_at',
            sortDesc: true,
            perPageOptions: [5, 10, 20, 50],
            showModal: false,
            modalPrompt: null,
            fields: [
                { key: 'index', label: '', sortable: false, tooltip: 'Index of the prompt' },
                { key: 'name', label: '', sortable: false, tooltip: 'Name of the author' },
                { key: 'actions', label: '', sortable: false, tooltip: 'Actions related to the prompt' },
                { key: 'created_at', label: 'Date Created', sortable: true, formatter: this.$formatDateSimple },
                { key: 'ai_model', label: 'Model', sortable: true, tooltip: 'LLM the prompt was submitted to.', },
                { key: 'accuracy', label: 'Accuracy', sortable: true, tooltip: 'How often the model gets the prediction right, out of all tries.' },
                { key: 'precision', label: 'Precision', sortable: true, tooltip: 'Precision of the prompt' },
                { key: 'recall', label: 'Recall', sortable: true, tooltip: 'Recall of the prompt' },
                { key: 'f_score', label: 'Coverage (F-score)', sortable: true, tooltip: 'F-score of the prompt' }
            ]
        };
    },
    computed: {
        challenge() {
            const challengeId = parseInt(this.$route.params.id)
            if (this.$store.user.created_challenges && challengeId in this.$store.user.created_challenges) {
                return this.$store.user.created_challenges[challengeId]
            } else if (this.$store.user.available_challenges && challengeId in this.$store.user.available_challenges) {
                return this.$store.user.available_challenges[challengeId]
            } else {
                return null
            }
        },
        filteredPrompts() {
            const completedPrompts = this.challenge.prompts.filter(prompt => prompt.status === 'completed');
            return this.personalBestOnly ? this.getPersonalBest(completedPrompts) : completedPrompts;
        },
        userId() {
            return this.$store.user.id;
        }
    },
    methods: {
        updateFilteredPrompts() {
            const completedPrompts = this.challenge.prompts.filter((prompt) => prompt.status === 'completed');
            this.filteredPrompts = this.personalBestOnly
                ? this.getPersonalBest(completedPrompts)
                : completedPrompts;
        },
        getPersonalBest(prompts) {
            return Object.values(prompts.reduce((acc, prompt) => {
                const authorId = prompt.author.id;
                if (!acc[authorId] || prompt.f_score > acc[authorId].f_score) {
                    acc[authorId] = prompt;
                }
                return acc;
            }, {}));
        },
        isChallengeOpen() {
            const today = new Date();
            const end = new Date(this.challenge.end);
            return end < today
        },
        daysUntil() {
            const today = new Date();
            const end = new Date(this.challenge.end);
            return Math.ceil((end - today) / (1000 * 60 * 60 * 24));
        },
        sanitizeInput(input) {
            return DOMPurify.sanitize(input);
        },
        async checkForUpdates() {
            await this.$store.getUpdatedPrompts(this.challenge.id)
            this.tableKey += 1
            this.$hardR
        },
        openModal(prompt) {
            this.modalPrompt = prompt
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
    }
};
</script>

<style scoped>
.table {
    border: none;
    border-collapse: collapse;
}

.table th,
.table td {
    border: none;
    padding: 0.5rem;
}

.table thead th {
    background: none;
}

.table tbody tr {
    border-bottom: 1px solid #ddd;
}

.b-pagination {
    display: flex;
    justify-content: flex-end;
}
</style>
