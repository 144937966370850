<template>
    <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
            <button class="navbar-toggler text-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"><i class="bi bi-list fs-1"></i></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav me-auto align-items-center">
                    <router-link to="/" class="navbar-brand ps-3 text-white" :class="{ active: $route.path === '/' }">
                        <img id="logo" :src="`${$hostURL}/media/logo.png`" alt="Top Prompt">
                    </router-link>
                    <li class="nav-item">
                        <router-link to="/challenges" class="nav-link text-white" :class="{ active: $route.path === '/challenges' }">Challenges</router-link>
                    </li>
                </ul>
                <ul class="navbar-nav ms-auto me-3">
                    <template v-if="$store.user && $store.isAuthenticated">
                        <template v-if="$store.user.is_staff">
                            <li class="nav-item">
                                <router-link to="/organizer/dashboard" class="nav-link text-white" :class="{ active: $route.path === '/organizer/dashboard' }">Organizer</router-link>
                            </li>
                        </template>
                        <template v-if="$store.user.is_superuser">
                            <li class="nav-item">
                                <a :href="`${$hostURL}/admin/`" class="nav-link text-white" :class="{ active: $route.path === '/admin/' }">Admin</a>
                            </li>
                        </template>
                        <li class="nav-item">
                            <router-link to="/account" class="nav-link text-white" :class="{ active: $route.path === '/account' }">Account</router-link>
                        </li>
                        <li class="nav-item">
                            <a href="#" @click.prevent="logout" class="nav-link text-white">Log out</a>
                        </li>
                    </template>
                    <router-link v-else to="/login" class="nav-link text-white" :class="{ active: $route.path === '/login' }">Log in</router-link>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>

export default {
    data() {
        return {
        };
    },
    computed: {
    },
    methods: {
        async logout() {
            try {
                await this.$store.logout(this.$router);
            } catch (error) {
                console.error(error);
            }
        }
    },
};
</script>

<style scoped>
    #logo {
        height: 30px;
    }
</style>
