<template>
    <div id="main-content">
        <div class="container pt-5">
            <div class="body-div">
                <div v-if="challenge.id in user.joined_challenges" class="container text-center mt-5">
                    <p>You are already registered for this challenge!</p>
                </div>
                <div v-else class="container text-center mt-5">
                    <h1>Welcome to {{ challenge.title }}</h1>
                    <p v-if="challenge.description">{{ challenge.description }}</p>
                    <div class="button-div">
                        <button @click="registerForChallenge" type="button" class="btn btn-lg p-4 btn-success text-white">
                            Register for Challenge
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        challenge: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
        };
    },
    computed: {
        user() {
            return this.$store.user;
        },
    },
    methods: {
        async registerForChallenge() {
            const updatedUser = { ...this.user };
            updatedUser.joined_challenges[this.challenge.id] = this.challenge;
            this.$store.updateUser(updatedUser);
            await this.$router.push({ name: 'challenge-detail', params: { id: this.challenge.id } });
        }
    }
};
</script>

<style scoped>
.body-div {
    display: flex;
    justify-content: center;
    font-size: 1.25rem;
}
.container {
    max-width: 600px;
}
.button-div {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}
</style>