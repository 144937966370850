<template>
    <h3 class="fw-bold">{{ draft.title }}</h3>

    <div class="row bg-white pt-3 pb-3">
        <div class="offset-0 offset-xl-3 col-12 col-xl-6">
            <nav class="organizer-setup-nav">
                <div v-for="(child, index) in childRoutes" :key="index" class="text-center p-2">
                    <a
                        :href="getLink(child.path)"
                        :class="{
                            'border-0 mb-2': true,
                            'bg-primary': isActive(child.meta.order),
                            'disabled': !isActive(child.meta.order)
                        }"
                        @click.prevent="navigateTo(child.path)"
                    >
                        <i
                            :class="[ 
                                'bi', 
                                child.meta.icon, 
                                'fs-5', 
                                isActive(child.meta.order) ? 'text-white' : ''
                            ]"
                            aria-hidden="true"
                        ></i>
                    </a>
                    <div :class="{ 'mt-2': true, 'fw-bold': isSelected(child.name) }">
                        {{ child.meta.title }}
                    </div>
                </div>
            </nav>
        </div>
    </div>
    <router-view 
        :draft="draft" 
        :errors="validationErrors"
        @update:draft="updateDraft" 
        @create:category="createCategory"
        @update:category="updateCategory"
        @delete:category="deleteCategory" 
        @create:api-key="createApiKey"
        @update:api-key="updateApiKey"
        @delete:api-key="deleteApiKey"
    />
</template>

<script>

export default {
    data() {
        return {
            validationErrors: [],
            childRoutes: []
        }
    },
    computed: {
        draft() {
            return this.$store.user.created_drafts[this.$route.params.id];
        }
    },
    watch: {
        '$route.params.id': {
            handler(newId) {
                this.draft = this.$store.user.created_drafts[newId];
                this.childRoutes = this.$route.matched.find(r => r.name === 'draft-detail')?.children || [];
            },
            immediate: true
        }
    },
    methods: {
        getLink(path) {
            return this.$router.resolve({
                name: 'draft-detail',
                params: { id: this.$route.params.id },
            }).href + '/' + path;
        },
        isActive(order) {
            return this.draft.setup_part.order >= order;
        },
        isSelected(name) {
            return this.$route.name === name;
        },
        navigateTo(path) {
            this.$router.push({
                path: `/organizer/draft/${this.$route.params.id}/${path}`
            });
        },
        async updateDraft(updatedDraft, fileUpload = false) {
            const nextPart = this.$route.meta.order + 1;
            const nextSetupPart = this.childRoutes.find(r => r.meta.order === nextPart) || null;
            if (nextSetupPart) {
                updatedDraft.setup_part_id = nextSetupPart.meta.id;
                const result = await this.$store.updateDraft(this.draft.id, updatedDraft);
                if (result.success) {
                    const updatedDraft = result.data;
                    this.$store.user.created_drafts[updatedDraft.id] = updatedDraft;
                    this.navigateTo(nextSetupPart.path);
                } else {
                    console.error(result.errors);
                }
            } else {
                const result = await this.$store.updateDraft(this.draft.id, updatedDraft, fileUpload);
                if (result.success) {
                    const newChallenge = result.data;
                    await this.$router.push({ name: 'organizer-challenge-detail', params: { id: newChallenge.id } });
                    this.$store.user.created_challenges[newChallenge.id] = newChallenge;
                    delete this.$store.user.created_drafts[newChallenge.id];
                } else {
                    this.validationErrors = result.errors;
                }
            }
        },
        createCategory() {
            this.$store.createCategory(this.draft.id);
        },
        updateCategory(updatedCategory) {
            this.$store.updateCategory(this.draft.id, updatedCategory);
        },
        deleteCategory(categoryId) {
            this.$store.deleteCategory(this.draft.id, categoryId);
        },
        createApiKey(modelId) {
            this.$store.createChallengeKey(this.draft.id, modelId);
        },
        updateApiKey(apiKey) {
            this.$store.updateChallengeKey(this.draft.id, apiKey);
        },
        deleteApiKey(modelId, keyId) {
            this.$store.deleteChallengeKey(this.draft.id, modelId, keyId);
        }
    },
    created() {
        this.draft = this.$store.user.created_drafts[this.$route.params.id];
        this.childRoutes = this.$route.matched.find(r => r.name === 'draft-detail')?.children || [];
    }
}
</script>
