<script>

export default {
    data() {
        return {
            
        }
    },
    methods: {
        async updateUser() {
            try {
                const response = await this.$http.put(`users/${this.$store.user.id}`, this.$store.user);
                if (response.status === 200) {
                    this.$store.user = response.data
                }
            } catch(error){
                console.error(error);
            }
        },
        async deleteAccount() {
            try {
                const response = await this.$http.delete('users/current');
                if (response.status === 200) {
                    this.$router.push('/login')
                    this.$store.user = null;
                    this.$store.isAuthenticated = false;
              }
            } catch (error) {
              console.error(error);
            }
        }
    }
}
</script>

<template>
    <div id="main-content" v-if="$store.user && $store.isAuthenticated">
        <div class="container-fluid body-container p-4">
            <div class="account-container">
                <div class="account-info">
                    <h2>Account Information</h2>
                    <form class="edit-form" @submit.prevent="updateUser">
                        <div class="form-group col-lg-4">
                            <label class="fw-bold" for="first_name">First name</label>
                            <input v-model="$store.user.first_name" type="text" class="form-control" maxlength="150">
                        </div>
                        <div class="form-group mt-3 col-lg-4">
                            <label class="fw-bold" for="last_name">Last name</label>
                            <input v-model="$store.user.last_name" type="text" class="form-control" maxlength="150">
                        </div>
                        <div class="form-group mt-3 col-lg-4">
                            <label class="fw-bold" for="email_address">Email Address</label>
                            <input v-model="$store.user.email" type="text" class="form-control" disabled maxlength="150">
                        </div>
                        <div class="form-group mt-3 col-lg-4">
                            <!-- <label class="fw-bold" for="id_form-{{forloop.counter0}}-key">{{form.instance.evaluation_model}} API Key</label> -->
                        </div>
                        <button type="submit" class="btn btn-primary mt-3">Update</button>
                    </form>
                </div>
            </div>
            <br>

            <div class="row">
                <h2>API key instructions</h2>
                <span>Instructions for obtaining an API key:</span><br>
                <a href="/account/api-key-instructions">Get API Key</a>
            </div>
            <div class="row mt-3">
                <h2>Setup Two-Factor Authentication</h2>
                <a href="/two-factor">Setup 2FA</a>
            </div>
            <br>

            <div>
                <h2>Account Actions</h2>
                    <!-- Button trigger modal -->
                <button type="button" class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#deleteAccountModal">Delete Account</button>
        
                <!-- Modal -->
                <div class="modal fade" id="deleteAccountModal" tabindex="-1" role="dialog" aria-labelledby="deleteAccountModalTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="deleteAccountModalTitle">Delete account?</h5>
                                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                Are you sure that you would like to delete your account?
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">No</button>
                                <button @click="deleteAccount" class="btn btn-danger" data-bs-dismiss="modal">Yes, Delete account</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
