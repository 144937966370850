<template>
  <div>
    <form @submit.prevent="login">
      <div v-if="requiresOtp" class="d-flex flex-column vh-100 mt-0 align-items-center justify-content-center">
        <div class="container">
          <div class="row p-5 ms-5 me-5 mb-5 bg-white rounded h-100">
            <div class="col-12 col-md-4">
              <h1 class="fw-bolder">Enter OTP code</h1>
            </div>
            <div class="col-12 col-md-8 d-flex flex-column align-items-center justify-content-center mt-5 mb-5">
              <input v-model="otpCode" class="form-control-lg border-0 w-100 mt-5 mb-2 bg-light" placeholder="Enter OTP" />
              <p v-if="error" class="text-danger">{{ error }}</p>
            </div>
          </div>
        </div>
        <div class="container-fluid d-flex justify-content-end position-absolute bottom-0">
          <div class="col-12 col-lg-4 mb-5 text-end">
              <button @click="submitOTP" class="btn btn-primary btn-lg col-3 m-5">Continue</button>
          </div>
        </div>
      </div>
      <div v-else class="d-flex flex-column vh-100 mt-0 align-items-center justify-content-center">
        <div class="container">
          <div class="row p-5 ms-5 me-5 mb-5 bg-white rounded h-100">
            <div class="col-12 col-md-4">
              <h1 class="fw-bolder">Sign In</h1>
            </div>
            <div class="col-12 col-md-8 d-flex flex-column align-items-center justify-content-center mt-5 mb-5">
              <input v-model="email" @input="resetError" 
                id="email" class="form-control-lg border-0 w-100 mt-5 mb-2 bg-light" 
                placeholder="Email address" type="text" autofocus="" autocapitalize="none" autocomplete="email" maxlength="150" required>
              <input v-model="password" @input="resetError" 
                id="password" class="form-control-lg border-0 w-100 mt-5 bg-light" 
                placeholder="Password" type="password" autocomplete="current-password" required>
              <div class="d-flex flex-row justify-content-between w-100 mt-3">
                <div class="text-primary border-primary">
                    <input type="checkbox" v-model="rememberMe" @input="resetError">
                    <label for="token-remember">&nbsp;Remember Me</label>
                </div>
                <a href="/accounts/password/reset/">FORGOT PASSWORD</a>
              </div>
              <p v-if="error" class="text-danger">{{ error }}</p>
              <input type="submit" value="" hidden="">
            </div>
          </div>
        </div>
        <div class="container-fluid d-flex justify-content-end position-absolute bottom-0">
          <div class="col-12 col-lg-4 mb-5 text-center">
              <a href="/register">Create Account</a>
              <button type="submit" class="btn btn-primary btn-lg col-3 m-5">Log In</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      rememberMe: false,
      otpCode: "",
      requiresOtp: false,
      error: ""
    };
  },
  mounted() {
    if (this.$store.isAuthenticated) {
      this.$router.push({ name: "challenges" });
    }
  },
  methods: {
    async login() {
      try {
        const response = await this.$store.login(this.email, this.password, this.rememberMe);

        if (response.requires_otp) {
          this.requiresOtp = true;
        } else if (!this.$store.isAuthenticated) {
          this.error = 'Login failed. Please check your credentials.';
        } else {
          await this.$router.push({ name: "challenges" });
        }
      } catch (error) {
        console.error(error);
        this.error = error.response?.data?.message || 'An error occurred during login.';
      }
    },
    async submitOTP() {
      try {
        const result = await this.$store.verifyOTP(this.email, this.otpCode, this.rememberMe);

        if (result.success) {
          await this.$router.push({ name: "challenges" });
        } else {
          this.error = result.message;
        }
      } catch (error) {
        console.error(error);
        this.error = error.response?.data?.message || 'An error occurred during OTP submission.';
      }
    },
    resetError() {
      this.error = "";
    }
  }
};
</script>
